exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-branches-[id]-js": () => import("./../../../src/pages/branches/[id].js" /* webpackChunkName: "component---src-pages-branches-[id]-js" */),
  "component---src-pages-branches-index-js": () => import("./../../../src/pages/branches/index.js" /* webpackChunkName: "component---src-pages-branches-index-js" */),
  "component---src-pages-branches-new-js": () => import("./../../../src/pages/branches/new.js" /* webpackChunkName: "component---src-pages-branches-new-js" */),
  "component---src-pages-catalog-[id]-js": () => import("./../../../src/pages/catalog/[id].js" /* webpackChunkName: "component---src-pages-catalog-[id]-js" */),
  "component---src-pages-catalog-all-js": () => import("./../../../src/pages/catalog/all.js" /* webpackChunkName: "component---src-pages-catalog-all-js" */),
  "component---src-pages-catalog-index-js": () => import("./../../../src/pages/catalog/index.js" /* webpackChunkName: "component---src-pages-catalog-index-js" */),
  "component---src-pages-catalog-search-js": () => import("./../../../src/pages/catalog/search.js" /* webpackChunkName: "component---src-pages-catalog-search-js" */),
  "component---src-pages-import-[id]-js": () => import("./../../../src/pages/import/[id].js" /* webpackChunkName: "component---src-pages-import-[id]-js" */),
  "component---src-pages-import-collections-js": () => import("./../../../src/pages/import/collections.js" /* webpackChunkName: "component---src-pages-import-collections-js" */),
  "component---src-pages-import-index-js": () => import("./../../../src/pages/import/index.js" /* webpackChunkName: "component---src-pages-import-index-js" */),
  "component---src-pages-import-products-js": () => import("./../../../src/pages/import/products.js" /* webpackChunkName: "component---src-pages-import-products-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventories-manage-js": () => import("./../../../src/pages/inventories/manage.js" /* webpackChunkName: "component---src-pages-inventories-manage-js" */),
  "component---src-pages-inventories-search-js": () => import("./../../../src/pages/inventories/search.js" /* webpackChunkName: "component---src-pages-inventories-search-js" */),
  "component---src-pages-orders-index-js": () => import("./../../../src/pages/orders/index.js" /* webpackChunkName: "component---src-pages-orders-index-js" */),
  "component---src-pages-orders-new-js": () => import("./../../../src/pages/orders/new.js" /* webpackChunkName: "component---src-pages-orders-new-js" */)
}

